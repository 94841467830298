import React, { useEffect } from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ASSET_NAME, getRecommendedListingParams, recommendedSectionId } from './LandingPage.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { searchListings } from '../SearchPage/SearchPage.duck';
import { useConfiguration } from '../../context/configurationContext';
import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import {
  SectionAutoCarousel,
  SectionGrid,
  SectionRecommendedListings,
} from '../PageBuilder/SectionBuilder';
import { BlockDiscoverFavouriteBrands, BlockGridCard } from '../PageBuilder/BlockBuilder';

const HeroSectionType = 'HeroSection';
const heroSectionId = 'hero-section';
const DiscoverFavouriteBrandsType = 'DiscoverFavouriteBrands';
const discoverFavouriteBrandsId = 'discover-brands';
const GridBlockCardType = 'GridBlockCard';
const DiscoverFavouriteBrandsBlockType = 'DiscoverFavouriteBrandsBlock';
const recommendedSectionType = 'recommended';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    recommendedListingIds,
    onFetchRecommendedListings,
    listings,
  } = props;

  const config = useConfiguration();

  useEffect(() => {
    const params = getRecommendedListingParams(config, recommendedListingIds);
    onFetchRecommendedListings(params, config);
  }, [recommendedListingIds]);

  // Construct custom page data
  const pageData = pageAssetsData?.[camelize(ASSET_NAME)]?.data;

  // Find all the custom blocks based on block IDs
  const gridBlocks = pageData?.sections.reduce((blocks, section) => {
    const matchingBlocks = section.blocks?.filter(block =>
      block?.blockId?.startsWith('grid-block-')
    );
    return matchingBlocks ? [...blocks, ...matchingBlocks] : blocks;
  }, []);

  // Find all the custom blocks based on block IDs
  const discoverFavouriteBrandsBlocks = pageData?.sections.reduce((blocks, section) => {
    const matchingBlocks = section.blocks?.filter(block =>
      block?.blockId?.startsWith('discover-favourite-brands-block-')
    );
    return matchingBlocks ? [...blocks, ...matchingBlocks] : blocks;
  }, []);

  // Find the correct custom section based on section id
  const heroSectionIdx = pageData?.sections.findIndex(s => s.sectionId === heroSectionId);
  const heroSection = pageData?.sections[heroSectionIdx];
  const recommendedSectionIdx = pageData?.sections.findIndex(
    s => s.sectionId === recommendedSectionId
  );
  const recommendedSection = pageData?.sections[recommendedSectionIdx];
  const discoverFavouriteBrandsIdx = pageData?.sections.findIndex(
    s => s.sectionId === discoverFavouriteBrandsId
  );

  const discoverFavouriteBrandsSection = pageData?.sections[discoverFavouriteBrandsIdx];

  const customDiscoverFavouriteBrandsSection = {
    ...discoverFavouriteBrandsSection,
    sectionId: discoverFavouriteBrandsId,
    sectionType: DiscoverFavouriteBrandsType,
  };

  // Define the necessary props for the hero section
  const customHeroSection = {
    ...heroSection,
    sectionId: heroSectionId,
    sectionType: HeroSectionType,
  };

  const customRecommendedSection = {
    ...recommendedSection,
    sectionId: recommendedSectionId,
    sectionType: recommendedSectionType,
    listings: listings,
  };

  // add the custom block to the custom section
  customHeroSection.blocks = gridBlocks || [];
  customDiscoverFavouriteBrandsSection.blocks = discoverFavouriteBrandsBlocks || [];

  // add block type to the custom block
  customHeroSection.blocks.forEach(block => {
    block.blockType = GridBlockCardType;
  });

  customDiscoverFavouriteBrandsSection.blocks.forEach(block => {
    block.blockType = DiscoverFavouriteBrandsBlockType;
  });

  const customSections = pageData
    ? [
        // add more sections here if needed
        ...pageData?.sections?.map((s, idx) => {
          if (idx === heroSectionIdx) {
            return customHeroSection;
          } else if (idx === discoverFavouriteBrandsIdx) {
            return customDiscoverFavouriteBrandsSection;
          } else if (idx === recommendedSectionIdx) {
            return customRecommendedSection;
          } else {
            return s;
          }
        }),
      ]
    : pageData;

  const customPageData = pageData
    ? {
        ...pageData,
        sections: customSections,
      }
    : pageData;

  return (
    <>
      <PageBuilder
        pageAssetsData={customPageData}
        inProgress={inProgress}
        options={{
          sectionComponents: {
            [HeroSectionType]: { component: SectionGrid },
            [DiscoverFavouriteBrandsType]: { component: SectionAutoCarousel },
            [recommendedSectionType]: { component: SectionRecommendedListings },
          },
          blockComponents: {
            [GridBlockCardType]: { component: BlockGridCard },
            [DiscoverFavouriteBrandsBlockType]: { component: BlockDiscoverFavouriteBrands },
          },
        }}
        error={error}
        fallbackPage={<FallbackPage error={error} />}
      />
    </>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  // These are the ids from the Console section
  const { recommendedListingIds } = state.LandingPage;
  // These are the ids for the listings returned from the API –
  // they may be different than the Console ones: if for example
  // one of the listings was closed, it does not get returned from the API
  const { currentPageResultIds } = state.SearchPage;
  const listings = getListingsById(state, currentPageResultIds);
  return { pageAssetsData, listings, inProgress, error, recommendedListingIds };
};

const mapDispatchToProps = dispatch => ({
  onFetchRecommendedListings: (params, config) => dispatch(searchListings(params, config)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LandingPageComponent);

export default LandingPage;
